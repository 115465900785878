/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import eeacmsvoltoAccordionBlock from '@eeacms/volto-accordion-block';
import kitconceptvoltoSliderBlock from '@kitconcept/volto-slider-block';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';
import voltoNaturgefahrentagung from 'volto-naturgefahrentagung';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';

const addonsInfo = [{"name":"@plone/volto-slate","version":"18.0.0-alpha.6","isPublishedPackage":true,"isRegisteredAddon":false,"modulePath":"/build/node_modules/@plone/volto-slate/src","packageJson":"/build/node_modules/@plone/volto-slate/package.json","addons":[]},{"name":"@eeacms/volto-accordion-block","version":"11.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@eeacms/volto-accordion-block/src","packageJson":"/build/node_modules/@eeacms/volto-accordion-block/package.json","addons":[]},{"name":"@kitconcept/volto-slider-block","version":"6.3.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@kitconcept/volto-slider-block/src","packageJson":"/build/node_modules/@kitconcept/volto-slider-block/package.json","addons":[]},{"name":"@eeacms/volto-matomo","version":"5.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/@eeacms/volto-matomo/src","packageJson":"/build/node_modules/@eeacms/volto-matomo/package.json","addons":[]},{"modulePath":"/build/src/addons/volto-naturgefahrentagung/src","packageJson":"/build/src/addons/volto-naturgefahrentagung/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"volto-naturgefahrentagung","addons":["@eeacms/volto-accordion-block","@kitconcept/volto-slider-block","@eeacms/volto-matomo"]},{"name":"volto-subblocks","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/volto-subblocks/src","packageJson":"/build/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-form-block","version":"3.8.2","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/node_modules/volto-form-block/src","packageJson":"/build/node_modules/volto-form-block/package.json","addons":["volto-subblocks"]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, eeacmsvoltoAccordionBlock, kitconceptvoltoSliderBlock, eeacmsvoltoMatomo, voltoNaturgefahrentagung, voltoSubblocks, voltoFormBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
