/**
 * OVERRIDE Logo.jsx
 * REASON: This theme uses a custom pre-@plone/components component
 * SemanticUI-free located at the components folder.
 * To override it, override the voltoweb one instead of
 * this one.
 */

import Logo from '../../../../../components/Logo/Logo';

export default Logo;
